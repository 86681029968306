var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_vm._v(" Open "),_c('b',[_vm._v("Odyssey")]),_vm._v(", go to the "),_c('b',[_vm._v("Labs")]),_vm._v(" tab, find the "),_c('i',[_vm._v("Acids & Bases section")]),_vm._v(", and click on "),_c('b',[_vm._v("K2 Comparing Oxoacids.")]),_vm._v(" Follow the instructions on the screen and click on all the available buttons. ")]),_c('ul',[_c('li',[_vm._v("What does the blueness indicated on the polarity maps of the chemicals?")]),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.options1,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('ul',[_c('li',[_vm._v("For the second set of molecules, which is the most acidic?")]),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.options2and3,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('ul',[_c('li',[_vm._v("For the second set of molecules, which is the least acidic?")]),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.options2and3,"item-text":"text","item-value":"value","label":"Select an option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }